import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Gutter } from '../../Root';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing.unit * 5,
  },
  img: {
    maxWidth: '100%',
  },
  title: {
    paddingBottom: theme.spacing.unit * 2,
  },
});

const Body = ({ classes }) => {

  return (
    <Gutter always>
      <Grid className={classes.root}>
        <Gutter always>
          <Typography variant="headline" className={classes.title}>
            Thank you for using Viasat Browser
          </Typography>
          <Typography component={'span'} paragraph>
            Viasat Browser will be discontinued on December 27, 2024.
          </Typography>
          <Typography component={'span'} paragraph>
            We strongly recommend taking these steps before that date:
            <ol>
              <li>Save your data
                <ul>
                  <li>Export your bookmarks</li>
                  <li>Save any stored passwords</li>
                </ul>
              </li>
              <li>Switch to a new browser
                <ul>
                  <li>Some browsers you may consider: Chrome, Edge, Firefox, or Safari</li>
                  <li>Import your saved data to the new browser</li>
                  <li>Make the new browser your default browser</li>
                </ul>
              </li>
              <li>Uninstall the Viasat Browser once you have set up the new browser</li>
            </ol>
          </Typography>
          <Typography component={'span'} paragraph>
            Need help? Check out our <a href="/migration-guide">step-by-step guide</a>.
          </Typography>
          <Typography component={'span'} paragraph>
            Questions? Contact us at <a href="mailto:browser-feedback@viasat.com">browser-feedback@viasat.com</a>.
          </Typography>
        </Gutter>
      </Grid>
    </Gutter>
  );
};

Body.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Body);
