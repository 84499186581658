import LocalizedStrings from 'react-localization';

let strings = {
  en: {
    title: 'Viasat Browser Migration Guide',
    headline:
      "You've come to the right place to learn more about Viasat Browser.",
    contact:
      'If you have problems with your Viasat Internet service, please contact {link}.',
    contact_link: 'customer support',

    general: {
      title: 'Viasat Browser General Info',

      what: {
        title: 'What was Viasat Browser?',
        body1:
          'Viasat Browser was a new type of web browser that served to be faster than all leading browsers on Viasat Internet. It also included an ad-blocking feature which made it more secure, while also helping to protect your privacy by blocking Internet trackers.',
        body2: 'It is available on Windows, macOS, Android, and iOS.',
      },
      how: {
        title: 'How did Viasat Browser work?',
        body:
          'Viasat Browser was built to find a smarter way to browse the Internet. To this end, we rethought how browsers work and built an innovative hinting system that allowed Viasat Browser to study and learn web pages in order to load the Internet in a better, faster way.',
      },
      why: {
        title: 'Why should I install Viasat Browser?',
        body:
          'By using Viasat Browser, you will have both a faster and a safer web experience on Viasat Internet when compared to leading web browsers. Plus, as a member of the Viasat Browser community, you will be helping to make a better web experience for all other users, just by surfing and enjoying the web like you always have, just faster.',
      },
      compare: {
        title: 'How does it compare to other browsers?',
        body_pre:
          "Viasat Browser's features align competitively with leading browsers, but with faster speed over Viasat Internet and robust security and privacy features. Thanks to its innovative hinting technology, it is able to load web pages faster than other leading browsers on Viasat Internet. See for yourself",
        body_link: 'here',
        body_post:
          'In addition, it has an integrated ad-blocker that helps protect you by blocking most malware and Internet trackers. Plus, get the content you love without unnecessary clutter or extra data consumption.',
      },
      exede: {
        title: 'How are Viasat & Exede Internet connected?',
        body: 'Viasat is the owner of Exede Internet.',
      },
      platform: {
        title: 'What platforms is Viasat Browser available for?',
        body: 'Viasat Browser is available for the following platforms:',
        windows: 'Windows 7 or later',
        macos: 'macOS Yosemite 10.10 or later',
        linux: 'Linux (Ubuntu 18.04+, CentOS 7+, Manjaro, and similar)',
        android: 'Android KitKat 4.4 or later',
        ios: 'iOS 11.0 or later',
      },
    },

    basic: {
      title: 'Getting started with Viasat Browser:',

      default: {
        title: 'How do I make Viasat Browser my default browser?',
        desktop: {
          title: 'Desktop (Windows, macOS, Linux)',
          body:
            'Open the browser, click on the three-dotted menu in the top right hand corner, select "Settings", then scroll to the bottom and click the button that says "Make Viasat Browser the default browser."',
        },
        android: {
          title: 'Android',
          body:
            'Tap the Settings icon (that looks like a gear), then tap on "Apps & notifications". Tap "Advanced". Tap "Default apps". Tap "Browser app", then tap the list entry for Viasat Browser. Press the Home button to return to the home screen.',
        },
        ios: {
          title: 'iOS',
          body1:
            'Setting Viasat Browser as the default browser on iOS requires iOS 14.',
          body2:
            'Open Viasat Browser. Tap the 3 horizontal dots on the bottom right of the app, then tap "Settings". Click on "Change Default Browser", which will direct you to the iOS settings app. Click on the menu item "Default Browser App" and select Viasat Browser.',
        },
      },
      bookmarks: {
        title: 'How do I import my bookmarks?',
        body:
          'For most desktop browsers, all you need to do is open Viasat Browser, click on {dots} in the top right corner, select {step1} > {step2}. From here, you can choose the browser from which you would like to import history, bookmarks, passwords, and search engines.',
        step1: 'Bookmarks',
        step2: 'Import Bookmarks and Settings...',
        firefox: {
          title:
            'If you are using Firefox Quantum you may need to complete the following additional steps:',
          step1:
            'In Firefox, type Ctrl+Shift+B (⌘⇧B on Mac) and select "Import and Backup" (star dropdown on Mac)',
          step2:
            'Select "Export Bookmarks to HTML" and save the bookmark file somewhere you can easily find it.',
          step3: 'In Viasat Browser, type {link} in the address bar',
          step4:
            'Select "Bookmarks HTML file" from the bottom of the dropdown menu (make sure the "Favorites/Bookmarks" box is checked), then click "Choose File" to import the bookmark file you just saved on your computer.',
        },
        edge: {
          title:
            'If you are using Microsoft Edge you may need to complete the following additional steps:',
          step1:
            'In Microsoft Edge, click on {dots} in the top right corner, select "Settings" then select "Import from another browser".',
          step2:
            'Select "Export to file" and save the bookmark file somewhere you can easily find it.',
          step3: 'In Viasat Browser, type {link} in the address bar',
          step4:
            'Select "Bookmarks HTML file" from the bottom of the dropdown menu (make sure the "Favorites/Bookmarks" box is checked), then click "Choose File" to import the bookmark file you just saved on your computer.',
        },
        mobile:
          'Unfortunately, there is no way to import bookmarks on Android or iOS.',
      },
      chrome: {
        title: 'Why does Viasat Browser look like Google Chrome?',
        body:
          'Like many users, we love Chrome; we just wanted it better and faster. We built Viasat Browser using the same open-source code base, preserving the security features, useful extensions, and look and feel of Chrome, but we added our own innovations to make it better.',
      },
      flash: {
        title: 'How do I run Adobe Flash content in Viasat Browser?',
        body:
          'The Internet has been moving away from using Flash content for years due to security and performance problems. Viasat Browser, like Chrome, has continued this route.',
        desktop: {
          title: 'Desktop (Windows, macOS, Linux)',
          body:
            'For desktop, it is now necessary to select certain sites to allow Flash to run if you need it. To do so, click on the menu {dots} in the top right-hand corner, and go to "Settings". Use the search bar to look for "flash", and it will highlight the "content settings" menu. Select that, and then select the highlighted "flash" menu. This will bring you to a page with "block" and "allow" lists. Add the URL of the site you wish to run Flash on to the Allow list.',
        },
        android: {
          title: 'Android',
          body: 'Viasat Browser has no Flash capabilities on Android.',
        },
        ios: {
          title: 'iOS',
          body: 'Viasat Browser has no Flash capabilities on iOS.',
        },
      },
      search: {
        title: 'How do I change my default search engine?',
        desktop: {
          title: 'Desktop (Windows, macOS, Linux)',
          step1: 'Click the 3 dot menu to the far right of the address bar.',
          step2: 'Click on Settings.',
          step3:
            'Scroll down the Settings menu until you see the search engine section.',
          step4:
            'First, see if the search engine you want to use is already enabled in Viasat Browser by clicking the menu to the right of "Search Engine used when searching from the address bar".',
          step5: 'If it is, select it, and leave the page. You are now set.',
          step6:
            'If it isn\'t, you will have to add it in the "Manage search engines" section.',
          step7:
            'Click the arrow to the right of the "Manage search engines" text.',
          step8: 'Click the ADD button below the default search engines list.',
          step9: 'In the popup window, enter:',
          step9a: 'The name of the search engine in the Search Engine field.',
          step9b: 'A descriptor for the search engine in the Keyword field.',
          step9c:
            'The address of the search engine follow by {query}, for example: {example}',
          step10: 'Click Add.',
          step11:
            'Find the entry you just made in the "Other search engines" list.',
          step12:
            'Click the 3 dots to the right of the entry and select "Make default".',
        },
        android: {
          title: 'Android',
          step1: 'Tap the 3 dot menu to the far right of the address bar.',
          step2: 'Tap on Settings.',
          step3:
            'Scroll down the Settings menu until you see the search engine section.',
          step4:
            'Check if the search engine on the menu option is the one you want to use.',
          step5: 'If so, tap Done.',
          step6: 'If not, tap Search Engine.',
          step7:
            'Tap the search engine name that you want from the list of search engines.',
          step8:
            'Tap the left-pointing back arrow until you return to the webview.',
        },
        ios: {
          title: 'iOS',
          step1: 'Tap the 3 dot menu to the far right of the address bar.',
          step2: 'Tap on Settings.',
          step3:
            'Scroll down the Settings menu until you see the search engine section.',
          step4:
            'Check if the search engine on the menu option is the one you want to use.',
          step5: 'If so, tap Done.',
          step6: 'If not, tap Search Engine.',
          step7:
            'Tap the search engine name that you want from the list of search engines.',
          step8: 'Tap Done.',
        },
      },
      data: {
        title: 'How do I save data when browsing?',
        body1:
          "Viasat Browser reduces the data you use to surf the web. The Autoplay Blocking feature blocks audio and video HTML5 streams from pre-loading so that you don't automatically consume data for content that you don't listen to or watch. Autoplay Blocking is on by default but you can disable it on a website by toggling the button under the Viasat ball menu.",
        body2:
          'Viasat Browser for iOS and Viasat Browser for Android do not have this feature because mobile devices do not autoplay videos.',
        body3:
          'Youtube users can also benefit from using the Audio Only Youtube extension, if they want to skip the video and just listen to audio. This will save data since only the audio track is used. The extension is available at {link}.',
      },
      update: {
        title: 'How do I update Viasat Browser?',
        desktop: {
          title: 'Desktop (Windows, macOS, Linux)',
          body1:
            'Navigate to the Viasat Browser help page by using the the menu in the top-right of the browser: click {dots} > Help > About Viasat Browser. Alternatively, use the URL bar to go to {link}.',
          body2:
            'Once the page fully loads, if there is a new version available, there will be a "Relaunch" button that looks like this:',
          body3:
            'Click "Relaunch" to restart your browser with the updated version.',
        },
      },
      windows64: {
        title:
          'How do I switch from the Windows 32-bit browser to the 64-bit browser?',
        body1:
          'First, download the installer for the 64-bit Viasat Browser by visiting {link} and clicking "Download Now". {emphasis}. You will first have to uninstall the version of Viasat Browser you currently have installed.',
        body2: 'To uninstall your existing Viasat Browser:',
        uninstall: {
          step1: 'Close any open Viasat Browser windows.',
          step2:
            'Click the Start menu, type "Control Panel", and click the Control Panel option that appears.',
          step3:
            'In the window that opens, under "Programs", click "Uninstall a program".',
          step4:
            'Find Viasat Browser in the list of installed programs and click on it.',
          step5: 'Click the "Uninstall" button at the top of that list.',
          step6:
            'You will see a box asking you to confirm. {emphasis} asking if you want to delete browsing data. If you check this, you will lose saved bookmarks and passwords.',
          emphasis: 'Do NOT check the box',
          step7: 'Reboot your machine.',
        },
        body3:
          'Now that Viasat Browser is uninstalled, you may install the 64-bit version of the browser by running the installer downloaded earlier. ',
        body4:
          'As a precaution, you may perform an AdBlock update as well. This will ensure AdBlock works with the new browser version. To do so, visit {link} and click the "Reload Filters" button.',
        body5:
          'You are good to go! The new browser will continue using your bookmarks and passwords from the previous version.',
        emphasis: 'Do NOT run it yet',
        link: 'the Viasat Browser homepage',
      },
      linux: {
        title: 'How do I install Viasat Browser on a Linux system?',
        body:
          'Viasat Browser for Linux comes in several types of packages. Debian packages are used on systems such as Debian or Ubuntu. RPM packages are used on systems such as Fedora or CentOS. Pacman packages are used in Arch Linux machines such as Manjaro.',
        netflix:
          'Note: that to use Netflix on Viasat Browser you may have to take additional steps. See the {link} for details.',
        netflix_link: 'FAQ entry',
        debian: {
          title: 'Installing Viasat Browser using the Debian package',
          step1:
            'You will first need to add the GPG key which apt will use to verify packages:',
          step2:
            'You will then copy the repository location to your list of sources:',
          step3: 'Now you can install:',
        },
        yum: {
          title: 'Installing Viasat Browser using the RPM package',
          step1:
            'You will first need to add the GPG key which yum will use to verify packages:',
          step2:
            'You will then copy the repository location to your list of sources:',
          step3: 'Now you can install:',
        },
        arch: {
          title: 'Installing Viasat Browser using the Pacman package',
          step1: 'Edit the /etc/pacman.conf file and add the repository:',
          step2: 'Add these lines to the end of the file:',
          step3: 'Now update your pacman sources:',
          step4: 'Now you can install:',
        },
      },
      netflix: {
        title: 'How do I use Netflix in Viasat Browser on Linux?',
        body:
          'Some versions of Viasat Browser do not come with complete support for watching Netflix. This is due to licensing requirements for the redistribution of the Content Delivery Module (CDM).',
        step1:
          'For Viasat Browser version 78 you will need to add complete support for Netflix by manually installing the CDM library for the version of Viasat Browser you are using.',
        step2: 'You first need to download the CDM zip file:',
        step3: 'Now extract the library into the correct place:',
        step4:
          'If you are using Viasat Browser 80 or newer you need to update the Widevine Content Decryption Module component.',
        step5:
          'Go to {link} and select "Check for update" under the "Widevine Content Decryption Module" component. The component should download and install.',
        step6:
          'You must also restart Viasat Browser so it will load the newly installed component.',
        step7: 'Now you can watch Netflix!',
      },
    },

    adblock: {
      title: 'Viasat Browser AdBlock',

      what: {
        title: 'What is Viasat Browser AdBlock?',
        body1:
          'Viasat Browser AdBlock blocks ads, malware, and trackers while you surf the web. This gives you greater security and privacy while loading your pages faster with fewer ads, saving you data.',
        body2:
          'You can tell that Viasat Browser AdBlock is turned on by looking for the green dot in the lower right corner of the Viasat Browser button:',
        body3:
          'Viasat recommends you have AdBlock turned on for all sites to maximize your experience, but if you notice a problem with the site, or want to support the site through its ads, you can easily turn it off on a site by site basis. All you have to do is click the Viasat Browser button to the right of the address bar and click the green toggle that appears.',
        body4:
          'In the same menu, you will find the link to the AdBlock settings where you can choose more advanced settings including enabling a different ad-blocker, or disabling the feature entirely.',
        body5:
          'Viasat Browser AdBlock is available on all platforms running Viasat Browser.',
      },
      why: {
        title: 'Why upgrade to Viasat Browser AdBlock?',
        body1:
          'Viasat Browser AdBlock is a powerful, built-in blocker which blocks ads, malware, and trackers the same as uBlock Origin and other ad-blocking extensions.',
        body2:
          "Unlike extensions, the Viasat Browsers built in AdBlock can operate faster, more efficiently, and won't become disabled due to changes from 3rd parties or Google.",
        body3:
          'Viasat Browser AdBlock uses the same filter lists as other major extension based ad-blockers, meaning you will have the same quality blocking with a better, integrated experience.',
      },
      content: {
        title:
          'How do I report ad content which is handled incorrectly by AdBlock?',
        body0:
          'Viasat Browser AdBlock uses the same filter lists as many popular ad blocking extensions and provides support for most filter styles found in these lists. But there will be cases when a website will have ad content which is not blocked or is missing content which was blocked by mistake. Updates to the public filter lists are done by volunteers who take requests from the public. One place where these requests can be made is the {link} web site.',
        easylistforum_link: 'EasyList Forum',
        body1:
          'To make requests you will need to create an account by registering with the forum. Make sure to read the {rules} concerning how to participate. Once you have an account you can post {new_topics} to the {unblocked} forum or the {incorrectly_removed} forum. Other site members will read your requests and update the public filter lists or make other recommendations.',
        rules: 'Board rules',
        new_topics: 'New Topics',
        unblocked: 'Report unblocked content',
        incorrectly_removed: 'Report incorrectly removed content',
        body2:
          'There are cases where the filter lists are up to date and correct but Viasat Browser AdBlock is still doing something wrong. A good way to figure out if this is the case is by comparing how Chrome works (using an ad blocking extension like uBlock Origin) with Viasat Browser AdBlock. If you think Viasat Browser is doing something wrong please report the issue by {feedback}',
        feedback: 'Sending Feedback',
      },
    },

    programs: {
      title: 'Viasat Browser and Other Programs',

      antivirus: {
        title:
          'My antivirus is trying to block Viasat Browser! What should I do?',
        body:
          "For programs that update regularly, like a web browser, antivirus programs can sometimes block updates initially, despite the fact that they are legitimate programs. If your antivirus program displays an alert for Viasat Browser, you will need to unblock the browser in your antivirus program's settings. If you aren't sure how to do this, try Googling your antivirus program's name and \"allow a blocked program.\"",
      },
      passwords: {
        title:
          "My password manager doesn't work with Viasat Browser. What can I do?",
        body:
          'We are working with various password manager companies, but not all of them support Viasat Browser yet. We have not yet confirmed what password managers work on mobile. Here are the ones that are currently confirmed to work on desktop:',
      },
    },

    trouble: {
      title: 'Troubleshooting',

      update: {
        title: 'Viasat Browser update problems',
        body:
          'If you are having problems updating Viasat Browser on your computer, try the possible fixes below.',

        temporary: {
          title:
            "Viasat Browser can't be updated, because it was opened from a read-only or a temporary location.",
          body:
            'Please {link} and open the downloaded "Viasat Browser.dmg" file. Do not double click on the Viasat Browser icon; instead, drag the Viasat Browser icon into the Applications folder. You can now run the Viasat Browser from your Applications.',
          link: 'redownload the Viasat Browser',
        },
        notary: {
          title:
            "Viasat Browser can't be opened because Apple cannot check it for malicious software.",
          body:
            'For improved security, macOS Catalina requires applications to pass additional security checks. Rest assured, Viasat Browser passes these checks, but it is possible you have an out-of-date version of the browser installed. Please {link} to update to the latest version of the browser.',
          link: 'redownload the Viasat Browser',
        },
        unreachable: {
          title:
            'An error occurred in retrieving update information. Please try again later.',
          body:
            "It's possible the Viasat Browser update servers are temporarily unreachable; try again in a short while.",
        },
        other: {
          title: 'Other update errors.',
          body1:
            'Please check your Internet connection, check that your {antivirus} and make sure your computer meets the minimum {requirements}.',
          body2: 'You may try {download}.',
          body3:
            'If you still experience update issues, please {feedback} and we will assist you.',
          antivirus: 'antivirus is not blocking Viasat Browser',
          requirements: 'system requirements',
          download: 'redownloading the Viasat Browser',
          feedback: 'send us a feedback',
        },
      },
      data: {
        title:
          'On some videos, the controls to start them are missing? What do I do?',

        desktop: {
          title: 'Desktop (Windows, macOS, Linux)',
          body:
            'The Audio/Video Data Saver Mode that prevents videos from auto-playing may result in the video controls being hidden. To view the video, right click on the image and select "Enable controls." Then press play on the controls like you would previously. If this becomes annoying, then disable the Audio/Video Data Saver Mode in the advanced section of the browser settings.',
        },
        android: {
          title: 'Android',
          body:
            'Viasat Browser for Android does not have the Audio/Video Data Saver feature, so this problem should not occur.',
        },
        ios: {
          title: 'iOS',
          body:
            'Viasat Browser for iOS does not have the Audio/Video Data Saver feature, so this problem should not occur.',
        },
      },
      reach: {
        title:
          "Why can't I reach a site with Viasat Browser, but I can reach it with another browser?",

        desktop: {
          title: 'Desktop (Windows, macOS, Linux)',
          body:
            'The ad blocker might be the issue, so try disabling it for that page by selecting the Viasat Button (Blue/Green ball in the upper right corner) and selecting the "AdBlock is Enabled" button. If you are using uBlock Origin, you will need to go through the uBlock Origin button (the red shield) and click on the circular On/Off switch. If disabling the ad blocker does not work, try clearing your browser cache and cookies "from the beginning of time" by clicking on the menu {dots} in the right hand corner, navigating to history, and clicking the "Clear browsing data" button.',
        },
      },
      location: {
        title:
          "Why does Viasat Browser think I'm in a different location than my real location?",

        desktop: {
          title: 'Desktop (Windows, macOS, Linux)',
          body1:
            'Browsers will often use a location service to figure out their true location or at least make a good guess as to the location of the computer or device which it is running on.',
          body2:
            'Viasat Browser uses a service called GeoIP which is generally pretty good at finding you based on your IP address. But there are some problems with using an IP address to locate your computer.',
          body3:
            'If your internet service is satellite based, for example Viasat Internet, the location of the IP address is more difficult to find since the IP address is assigned at the central ground station end of the satellite link. For situations like this your browser may report that you are located in the middle of a desert, hundreds of miles from your real location.',
          body4:
            'If you find that your location is incorrect or if you want Viasat Browser to provide a different location than what it currently reports, we recommend you install a location spoofing extension. You can find such an extension by going to',
          body5: 'and doing a search for "Location Guard".',
          body6:
            'This extension has been tested by the Viasat Browser team and works by allowing you to set a Fixed Location of your choice. Use the map provided by the extension to find your true location and set the "Fixed Location" blue pin on that spot. That\'s all you need to do. From that point on Viasat Browser will report that as your true location to any web site which asks for it.',
        },
      },
      fast: {
        title: "What do I do if Viasat Browser doesn't seem faster?",
        body:
          'For testing purposes, Viasat Browser sometimes randomly turns off some optimizations to place the browser in a Chromium-like mode in order to test Chromium performance versus Viasat Browser performance. To check if your browser is running in Chromium-like mode, go to {link} and check if the word "Chromiumlike" appears in the command line. If it does, you can restore Viasat Browser optimizations by restarting your browser.',
      },
      black: {
        title: 'Viasat Browser appears as a black screen. What do I do?',
        body:
          'Viasat Browser contains a feature called "hardware acceleration" that, in rare cases, is incompatible with some graphics cards. If your Viasat Browser appears as a black window when you open it, follow these steps to turn off hardware acceleration:',

        desktop: {
          title: 'Desktop (Windows)',
          step1: 'Hit the Start button to open the Start menu.',
          step2:
            'Type in "cmd" and click on "cmd.exe" in the list of options that is displayed, shown here:',
          step3a: 'Enter the following (including the quotation marks):',
          step3b: 'Entering the above text should look like this:',
          step4a:
            'Hit enter and this will open Viasat Browser with the hardware acceleration feature temporarily disabled.',
          step4b:
            'Note: When you hit enter, if you get an error message saying "The system cannot find the path specified", then run this command instead:',
          step5:
            'In Viasat Browser, click the three vertical dots {dots} near the top-right of the window.',
          step6: 'In the menu that opens, click on "Settings".',
          step7:
            'Scroll to the bottom and click on the "Advanced ▼" button. This will show more options.',
          step8a:
            'Scroll to the bottom again, and in the section labeled "System", turn off the toggle labeled "Use hardware acceleration when available".',
          step8b: 'That setting should now look like this:',
          step9:
            'Hardware acceleration is now permanently disabled. Close Viasat Browser and then close the command prompt. You may now open Viasat Browser in the normal manner.',
        },
      },
      snap: {
        title: 'Viasat Browser shows an "Aw, Snap!" on all websites',
        body1:
          'Version 80 of Viasat Browser contains a protective feature called "renderer code integrity", which some Windows antivirus programs interact poorly with. First, be sure that the antivirus program you have installed is up-to-date. If updating the antivirus does not help, you may disable the protective feature.',
        body2:
          'To do so, right-click your Viasat Browser shortcut, and choose "Properties". In the box labeled "Target", add a space after the final quotation mark, then add {flag}. For example, the entire "Target" field may now be:',
      },
      account: {
        title: 'Logging into my Viasat Internet account gives me a SAML error.',
        body1:
          'While not an issue specific to Viasat Browser, we have received a number of complaints about this from customers of Viasat Internet. The error looks like this:',
        body2:
          'If you receive this error, simply open a new tab and navigate to {link}. It should take you to your account page. Then, close the tab with the error.',
      },
    },

    help: {
      title: 'Need more help?',

      feedback: {
        title: 'How do I report an issue to the Viasat Browser team?',
        body1: 'You may report an issue or send us a feedback by clicking the',
        body2:
          'icon in the top-right of your browser window and selecting "Report an issue". In the form that appears, in order for us to be able to reply to you, be sure to include your email address. Provide as much detail as you can on the issue you are experiencing.',
      },
      question: {
        title: "I don't see my question listed. What should I do?",
        body:
          'If you have a question or issue not already addressed, we are here to help. Please {link} and we will get back to you with an answer or solution to help make your Viasat Browser experience the best possible.',
        link: 'send us a feedback',
      },
    },
  },
};

const localized = new LocalizedStrings(strings, { logsEnabled: false });
export default localized;
