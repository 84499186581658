import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import Section from './Section';
import Strings from './Strings.js';
import Typography from '@material-ui/core/Typography';
import { Gutter } from '../../Root';
import { withStyles } from '@material-ui/core/styles';

const faqStyles = (theme) => ({
  special: {
    backgroundColor: theme.palette.background.code,
    borderRadius: '3px',
    margin: '2px',
    padding: '2px 5px',
    fontWeight: 'bold',
  },
  wideImage: {
    width: '100%',
    maxWidth: '100%',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  image: {
    border: 'solid 1px ' + theme.palette.background.border,
    marginTop: theme.spacing.unit * 3 + 'px',
    marginBottom: theme.spacing.unit * 3 + 'px',
    borderRadius: '4px',
  },
  subFaqTitle: {
    padding: theme.spacing.unit * 2,
    backgroundColor: '#e1e1e1',
  },
  // TODO: create CodeBlock and CodeSpan components in common/
  codeBlock: {
    padding: theme.spacing.unit,
    display: 'block',
    overflowY: 'scroll',
    backgroundColor: theme.palette.background.code,
    borderRadius: '3px',
    margin: theme.spacing.unit + 'px 0',
  },
  codeBlockBash: {
    display: 'block',
    userSelect: 'all',
    '&::before': {
      content: '"$ "',
    },
  },
  codeBlockText: {
    display: 'block',
    userSelect: 'all',
  },
  codeSpan: {
    backgroundColor: theme.palette.background.code,
    borderRadius: '3px',
    margin: '2px',
    padding: '2px 5px',
    whiteSpace: 'nowrap',
  },
});

const FAQS_COPY = [
  {
    title: "How do I transfer Viasat Browser data to another browser?",
    faqs: [
      {
        name: 'transfer-to-chrome',
        title: "Transfer data to Chrome",
        body: React.createElement(
          withStyles(faqStyles)(({ classes }) => (
            <React.Fragment>
              <Typography variant="subheading" paragraph>
                Bookmarks
              </Typography>
              <Typography component={'span'} paragraph>
                In Viasat Browser, navigate to <code>viasat://bookmarks</code>. Click the <span className={classes.special}>⋮</span>
                button on the top-right of this page, and select "Export bookmarks". In the dialog that opens, select a location to
                save the bookmarks file.
              </Typography>
              <Typography component={'span'} paragraph>
                In Chrome, navigate to <code>chrome://bookmarks</code>. Click the <span className={classes.special}>⋮</span>
                button on the top-right of this page, and select "Import bookmarks". In the dialog that opens, select the previously
                saved bookmarks file.
              </Typography>
              <Typography component={'span'} paragraph>
                You may now delete the previously saved bookmarks file.
              </Typography>

              <Typography variant="subheading" paragraph>
                Passwords
              </Typography>
              <Typography component={'span'} paragraph>
                In Viasat Browser, navigate to <code>viasat://settings/passwords</code>. Click the <span className={classes.special}>⋮</span>
                button on the right-side of this page (just below the "Auto Sign-In" option), and select "Export passwords".
                In the dialog that opens, select a location to save the passwords file.
              </Typography>
              <Typography component={'span'} paragraph>
                In Chrome, navigate to <code>chrome://password-manager/passwords</code>. Click the button to import passwords from a CSV file,
                and choose the previously saved passwords file.
              </Typography>
              <Typography component={'span'} paragraph>
                In the next dialog that appears, we highly recommend selecting the option to delete the passwords file.
              </Typography>

              <Typography variant="subheading" paragraph>
                Extensions
              </Typography>
              <Typography component={'span'} paragraph>
                Unfortunately, it is not possible to transfer extensions from Viasat Browser to Chrome. However, we do
                recommend installing <a href="https://chromewebstore.google.com/detail/ublock-origin/cjpalhdlnbpafiamejdnhcphjbkeiagm">uBlock Origin</a>{' '}
                as a replacement for Viasat Browser AdBlock.
              </Typography>
            </React.Fragment>
        )))
      },
      {
        name: 'transfer-to-edge',
        title: "Transfer data to Edge",
        body: React.createElement(
          withStyles(faqStyles)(({ classes }) => (
            <React.Fragment>
              <Typography variant="subheading" paragraph>
                Bookmarks
              </Typography>
              <Typography component={'span'} paragraph>
                In Viasat Browser, navigate to <code>viasat://bookmarks</code>. Click the <span className={classes.special}>⋮</span>
                button on the top-right of this page, and select "Export bookmarks". In the dialog that opens, select a location to
                save the bookmarks file.
              </Typography>
              <Typography component={'span'} paragraph>
                In Edge, navigate to <code>edge://settings/profiles/importBrowsingData</code>. In the "Import from other browsers" section,
                click the "Choose what to import" button. In the dialog that opens, in the "Import from" drop-down menu, select "favorites or bookmarks HTML file".
                Click the "Choose file" button and select the previously saved bookmarks file.
              </Typography>
              <Typography component={'span'} paragraph>
                You may now delete the previously saved bookmarks file.
              </Typography>

              <Typography variant="subheading" paragraph>
                Passwords
              </Typography>
              <Typography component={'span'} paragraph>
                In Viasat Browser, navigate to <code>viasat://settings/passwords</code>. Click the <span className={classes.special}>⋮</span>
                button on the right-side of this page (just below the "Auto Sign-In" option), and select "Export passwords".
                In the dialog that opens, select a location to save the passwords file.
              </Typography>
              <Typography component={'span'} paragraph>
                In Edge, navigate to <code>edge://settings/profiles/importBrowsingData</code>. In the "Import from other password managers" section,
                click the "Import passwords" button. In the dialog that opens, click the "Choose file" button and select the previously saved passwords file.
              </Typography>
              <Typography component={'span'} paragraph>
                We highly recommend deleting the previously saved passwords file.
              </Typography>

              <Typography variant="subheading" paragraph>
                Extensions
              </Typography>
              <Typography component={'span'} paragraph>
                Unfortunately, it is not possible to transfer extensions from Viasat Browser to Edge. However, we do
                recommend installing <a href="https://chromewebstore.google.com/detail/ublock-origin/cjpalhdlnbpafiamejdnhcphjbkeiagm">uBlock Origin</a>{' '}
                as a replacement for Viasat Browser AdBlock.
              </Typography>
            </React.Fragment>
        )))
      },
      {
        name: 'transfer-to-firefox',
        title: "Transfer data to Firefox",
        body: React.createElement(
          withStyles(faqStyles)(({ classes }) => (
            <React.Fragment>
              <Typography variant="subheading" paragraph>
                Bookmarks
              </Typography>
              <Typography component={'span'} paragraph>
                In Viasat Browser, navigate to <code>viasat://bookmarks</code>. Click the <span className={classes.special}>⋮</span>
                button on the top-right of this page, and select "Export bookmarks". In the dialog that opens, select a location to
                save the bookmarks file.
              </Typography>
              <Typography component={'span'} paragraph>
                In Firefox, navigate to <code>about:preferences</code>. In the "Import Browser Data" section, click the "Import Data" button.
                In the dialog that opens, in the "Import Browser Data" drop-down, select "Bookmarks from HTML file", and then select the
                previously saved bookmarks file.
              </Typography>
              <Typography component={'span'} paragraph>
                You may now delete the previously saved bookmarks file.
              </Typography>

              <Typography variant="subheading" paragraph>
                Passwords
              </Typography>
              <Typography component={'span'} paragraph>
                In Viasat Browser, navigate to <code>viasat://settings/passwords</code>. Click the <span className={classes.special}>⋮</span>
                button on the right-side of this page (just below the "Auto Sign-In" option), and select "Export passwords".
                In the dialog that opens, select a location to save the passwords file.
              </Typography>
              <Typography component={'span'} paragraph>
                In Firefox, navigate to <code>about:preferences</code>. In the "Import Browser Data" section, click the "Import Data" button.
                In the dialog that opens, in the "Import Browser Data" drop-down, select "Passwords from CSV file", and then select the
                previously saved passwords file.
              </Typography>
              <Typography component={'span'} paragraph>
                We highly recommend deleting the previously saved passwords file.
              </Typography>

              <Typography variant="subheading" paragraph>
                Extensions
              </Typography>
              <Typography component={'span'} paragraph>
                Unfortunately, it is not possible to transfer extensions from Viasat Browser to Firefox. However, we do
                recommend installing <a href="https://addons.mozilla.org/en-US/firefox/addon/ublock-origin/">uBlock Origin</a>{' '}
                as a replacement for Viasat Browser AdBlock.
              </Typography>
            </React.Fragment>
        )))
      },
      {
        name: 'transfer-to-safari',
        title: "Transfer data to Safari",
        body: React.createElement(
          withStyles(faqStyles)(({ classes }) => (
            <React.Fragment>
              <Typography variant="subheading" paragraph>
                Bookmarks
              </Typography>
              <Typography component={'span'} paragraph>
                In Viasat Browser, navigate to <code>viasat://bookmarks</code>. Click the <span className={classes.special}>⋮</span>
                button on the top-right of this page, and select "Export bookmarks". In the dialog that opens, select a location to
                save the bookmarks file.
              </Typography>
              <Typography component={'span'} paragraph>
                In Safari, select the <span className={classes.special}>File</span> menu, then hover over the <span className={classes.special}>Import From</span>
                submenu and select "Bookmarks HTML file". Then select the previously saved bookmarks file.
              </Typography>
              <Typography component={'span'} paragraph>
                You may now delete the previously saved bookmarks file.
              </Typography>

              <Typography variant="subheading" paragraph>
                Passwords
              </Typography>
              <Typography component={'span'} paragraph>
                In Viasat Browser, navigate to <code>viasat://settings/passwords</code>. Click the <span className={classes.special}>⋮</span>
                button on the right-side of this page (just below the "Auto Sign-In" option), and select "Export passwords".
                In the dialog that opens, select a location to save the passwords file.
              </Typography>
              <Typography component={'span'} paragraph>
                In Safari, select the <span className={classes.special}>File</span> menu, then hover over the <span className={classes.special}>Import From</span>
                submenu and select "Passwords CSV file" and click "Choose File". Then select the previously saved passwords file.
              </Typography>
              <Typography component={'span'} paragraph>
                We highly recommend deleting the previously saved passwords file.
              </Typography>

              <Typography variant="subheading" paragraph>
                Extensions
              </Typography>
              <Typography component={'span'} paragraph>
                Unfortunately, it is not possible to transfer extensions from Viasat Browser to Safari.
              </Typography>
            </React.Fragment>
        )))
      },
    ]
  },



  {
    title: "How do I uninstall Viasat Browser?",
    faqs: [
      {
        name: 'migration-windows11',
        title: "Windows 11",
        body: React.createElement(
          withStyles(faqStyles)(({ classes }) => (
            <React.Fragment>
              <Typography component={'span'} paragraph>
                <ol>
                  <li>On your computer, close all Viasat Browser windows.</li>
                  <li>Open the Start menu and then click <span className={classes.special}>Settings</span>.</li>
                  <li>Click <span className={classes.special}>Apps</span> and then <span className={classes.special}>Installed apps</span>.</li>
                  <li>Next to Viasat Browser, click <span className={classes.special}>⋯</span> and then <span className={classes.special}>Uninstall</span>.</li>
                  <li>Click <span className={classes.special}>Uninstall</span>.</li>
                  <li>If asked to allow the app the make changes, click <span className={classes.special}>Yes</span>.</li>
                  <li>To delete your profile information, like bookmarks and history, check "Also delete your browsing data."</li>
                </ol>
              </Typography>
            </React.Fragment>
        )))
      },
      {
        name: 'migration-windows10',
        title: "Windows 10",
        body: React.createElement(
          withStyles(faqStyles)(({ classes }) => (
            <React.Fragment>
              <Typography component={'span'} paragraph>
                <ol>
                  <li>On your computer, close all Viasat Browser windows.</li>
                  <li>Open the Start menu and then click <span className={classes.special}>Settings</span>.</li>
                  <li>Click <span className={classes.special}>Apps</span>.</li>
                  <li>Under "Apps & features," find and click Viasat Browser.</li>
                  <li>Click <span className={classes.special}>Uninstall</span>.</li>
                  <li>Confirm by clicking <span className={classes.special}>Uninstall</span>.</li>
                  <li>If asked to allow the app the make changes, click <span className={classes.special}>Yes</span>.</li>
                  <li>To delete your profile information, like bookmarks and history, check "Also delete your browsing data."</li>
                  <li>Click Uninstall.</li>
                </ol>
              </Typography>
            </React.Fragment>
        )))
      },
      {
        name: 'migration-macos',
        title: "Mac",
        body: React.createElement(
          withStyles(faqStyles)(({ classes }) => (
            <React.Fragment>
              <Typography component={'span'} paragraph>
                <ol>
                  <li>On your mac, quit Viasat Browser if it is open.</li>
                  <li>Open Finder.</li>
                  <li>Go to the folder containing the Viasat Browser application on your computer.</li>
                  <ul><li>It is most likely the Applications folder. If not, go to File and then Find, and search for "Viasat Browser."</li></ul>
                  <li>Drag Viasat Browser to the Trash.</li>
                </ol>
              </Typography>
            </React.Fragment>
        )))
      },
      {
        name: 'migration-ios',
        title: "iPhone or iPad",
        body: React.createElement(
          withStyles(faqStyles)(({ classes }) => (
            <React.Fragment>
              <Typography component={'span'} paragraph>
                <ol>
                  <li>On your iPhone or iPad, touch and hold Viasat Browser.</li>
                  <li>At the top left of the icon, tap <span className={classes.special}>Remove app</span>.</li>
                  <li>Tap <span className={classes.special}>Delete app</span>.</li>
                </ol>
              </Typography>
            </React.Fragment>
        )))
      },
      {
        name: 'migration-android',
        title: "Android",
        body: React.createElement(
          withStyles(faqStyles)(({ classes }) => (
            <React.Fragment>
              <Typography component={'span'} paragraph>
                <ol>
                  <li>Open the Google Play Store app on your device. </li>
                  <li>Tap the <span className={classes.special}>Menu</span> icon, then <span className={classes.special}>My Apps</span>.</li>
                  <li>Tap the Viasat Browser app and then tap <span className={classes.special}>Uninstall</span>.</li>
                </ol>
              </Typography>
            </React.Fragment>
        )))
      },
    ]
  },
];

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing.unit * 5,
    marginBottom: theme.spacing.unit * 5,
  },
  title: {
    paddingTop: theme.spacing.unit * 4,
    color: theme.palette.primary.dark,
  },
});

const FAQs = ({ classes }) => (
  <Gutter>
    <Grid container direction="column" className={classes.root}>
      <Gutter always>
        <Typography variant="display3">{Strings.title}</Typography>
        <Typography variant="subheading" paragraph>
          {Strings.formatString(Strings.contact, {
            link: (
              <a href="https://www.viasat.com/customer-service/">
                {Strings.contact_link}
              </a>
            ),
          })}
        </Typography>
        <Typography variant="headline" className={classes.title}>
          Leaving Viasat Browser
        </Typography>
        <Typography component={'span'} paragraph>
          To assist you with leaving Viasat Browser, this page contains instructions on how to transfer your
          bookmarks, passwords, and extensions to another browser of your choice, and on how to uninstall Viasat Browser.
          To have an experience that is most like Viasat Browser, we recommend switching to <a href="/migration-guide#transfer-to-chrome">Google Chrome</a>.
          We have also provided instructions for <a href="/migration-guide#transfer-to-edge">Microsoft Edge</a>,{' '}
          <a href="/migration-guide#transfer-to-firefox">Mozilla Firefox</a>, and <a href="/migration-guide#transfer-to-safari">Apple Safari</a>.
        </Typography>
        <Typography component={'span'} paragraph>
          Transferring data to another browser is unfortunately limited to desktop devices, as it is not possible
          to to transfer data from Viasat Browser to another browser on Android or iOS.
        </Typography>
      </Gutter>
      <Gutter>
        {FAQS_COPY.map((section, i) => (
          <Section key={i} {...section} />
        ))}
      </Gutter>
    </Grid>
  </Gutter>
);

FAQs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FAQs);
